var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.notice)?_c('div',{staticClass:"w-full px-4 py-2 text-center bg-yellow-600 text-white mb-2"},[_vm._v(" "+_vm._s(_vm.notice)+" ")]):_vm._e(),_c('div',{staticClass:"flex flex-row h-screen antialiase text-gray-800",staticStyle:{"height":"90vh"}},[_c('div',{staticClass:"flex flex-col h-full w-full bg-white relative"},[_c('div',{staticClass:"h-full overflow-hidden"},[_c('div',{staticClass:"h-full overflow-y-auto"},[_c('div',{staticClass:"grid grid-cols-12 gap-y-2"},_vm._l((_vm.messages),function(message,key){return _c('div',{key:key,class:[
								'message-text',
								message.userId != _vm.senderId
									? 'col-start-1 col-end-11 p-2 rounded-2xl'
									: 'col-start-2 col-end-13 p-2 rounded-2xl'
							]},[_c('div',{class:[
									message.userId != _vm.senderId
										? 'flex flex-row items-start'
										: 'flex items-start justify-start flex-row-reverse'
								]},[_c('div',[_c('div',{staticClass:"flex items-center justify-center h-10 w-10 rounded-full text-white flex-shrink-0 mx-2 bg-cover bg-no-repeat",style:(("background-color: " + (_vm.letterColor(
												message.owner.name
											)) + "; background-image: url(" + (_vm.$constants.STORAGE_PATH) + (message.owner.avatar) + ")"))},[(!message.owner.avatar)?_c('span',{staticClass:"uppercase"},[_vm._v(_vm._s(message.owner.name.charAt(0)))]):_vm._e()]),(message.userId != _vm.senderId)?_c('div',{staticClass:"text-xs text-grey-400 w-10 m-auto text-center"},[_vm._v(" "+_vm._s(message.owner.name)+" ")]):_vm._e()]),_c('div',{staticClass:"relative"},[_c('div',{class:[
											'text-sm py-2 px-4 shadow rounded-2xl',
											message.userId != _vm.senderId
												? 'bg-white'
												: 'bg-indigo-100'
										]},[_vm._v(" "+_vm._s(message.message)+" ")]),(message.seen)?_c('small',{class:[
											'ml-2 text-grey-400 my-1',
											message.userId != _vm.senderId
												? 'hidden'
												: 'float-right'
										]},[_c('i',{staticClass:"icofont-check opacity-75"}),_vm._v("vue ")]):_vm._e()])])])}),0)])]),_c('div',{staticClass:"flex flex-row items-stretch sticky bottom-0 bg-white w-full shadow px-2 py-2"},[_c('div',{staticClass:"p-1 px-2"},[_c('button',{staticClass:"flex items-center justify-center text-6 rounded-full bg-gray-200 text-white shadow-md h-10 w-10 hover:bg-gray-300",on:{"click":_vm.sendHi}},[_vm._v(" 👋 ")])]),_c('div',{staticClass:"flex flex-row items-center w-full rounded h-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"rounded-full border border-transparent w-full focus:outline-none text-sm h-10 flex items-center",attrs:{"type":"text","placeholder":"Type your message...."},domProps:{"value":(_vm.form.message)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addMessage.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}})]),_c('div',{staticClass:"p-1 px-2"},[_c('button',{staticClass:"flex items-center justify-center text-6 rounded-full bg-gray-200 text-white shadow-md h-10 w-10 hover:bg-gray-300 bg-success",on:{"click":_vm.addMessage}},[_c('i',{staticClass:"icofont-send-mail"})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }