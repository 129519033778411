<template>
	<div>
		<div>
			<div
				v-if="image"
				:style="`background-image: url(${image})`"
				class="w-24 h-24 bg-contain bg-no-repeat m-auto rounded-full border border-gray-300 bg-center"
			/>
			<div
				v-else-if="value"
				:style="`background-image: url(${value})`"
				class="w-24 h-24 bg-contain bg-no-repeat m-auto rounded-full border border-gray-300 bg-center"
			/>
		</div>
		<div class="mt-4">
			<t-button
				class="bg-white w-full shadow-none"
				@click="$refs['upload-file-input'].click()"
				type="button"
			>
				<span class="text-grey-700"
					><i class="icofont-upload-alt"></i> Upload from
					gallery</span
				>
			</t-button>
			<input
				ref="upload-file-input"
				type="file"
				class="w-full p-3 border-gray-300 border rounded hidden"
				v-on:change="onFileChange"
			/>
		</div>
	</div>
</template>
<script>
export default {
	props: ['value'],
	data: () => ({
		image: ''
	}),
	beforeMount() {},
	methods: {
		onFileChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
		},
		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.image = e.target.result;
				this.$emit('input', this.image);
			};
			reader.readAsDataURL(file);
		}
	}
};
</script>
<style scoped>
.img-div {
	border-radius: 4px;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 50px;
}
</style>
