<template>
	<div class="pb-0 rounded">
		<div class="modal-dialog">
			<div class="modal-content modal-position-hight ">
				<div
					class="modal-body bg-img-white p-3 rounded"
					:style="
						`background-image: url(${$root.env.VUE_APP_PUBLIC_PATH}img/bg1.jpg)`
					"
				>
					<a
						@click="$emit('back')"
						class="font-weight-bold text-magenta text-decoration-none"
					>
						<!-- <i class="icofont-rounded-left back-page"></i> -->

						<h6 class="font-weight-bold text-dark">
							<i
								class="icofont-arrow-left text-magenta back-page"
							/>
							Invite a person
						</h6>
					</a>

					<div class="order-body px-3 mt-8">
						<p class="text-addcontact px-3">
							Lorem ipsum dolor sit amet consectetur adipisicing
							elit. Autem earum
						</p>
						<form action="verification.html" class="mt-2">
							<div class="form-group pr-3 pl-3">
								<!-- <label for="input_name1">Nom</label> -->
								<input
									placeholder="Nom"
									v-model="form.name"
									type="text"
									class="form-control"
									aria-describedby="emailHelp"
								/>
							</div>
							<div class="form-group pr-3 pl-3">
								<!-- <label for="input_number1">Telephone</label> -->

								<input
									placeholder="Email"
									v-model="form.email"
									type="email"
									class="form-control"
									aria-describedby="emailHelp"
								/>
							</div>
							<div class="form-group pr-3 pl-3">
								<!-- <label for="input_email1">Email</label> -->
								<input
									placeholder="Telephone"
									v-model="form.phone"
									type="number"
									class="form-control"
									aria-describedby="emailHelp"
								/>
							</div>
							<div class="form-group pr-3 pl-3">
								<!-- <label for="input_number1">Telephone</label> -->

								<textarea
									placeholder="Message"
									v-model="form.message"
									type="text"
									class="form-control"
									aria-describedby="emailHelp"
								/>
							</div>
						</form>
					</div>
				</div>
				<div
					class="modal-footer p-3 border-0"
					:style="
						`background-image: url(${$root.env.VUE_APP_PUBLIC_PATH}img/bg1.jpg)`
					"
				>
					<div class="col-12 m-0 p-3">
						<button
							@click="addContact"
							type="button"
							class="btn bg-magenta text-white btn-lg btn-block rounded"
						>
							A J O U T E R
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { AddContact } from '@/tools/apiService';
export default {
	props: ['commandId'],
	data: () => ({
		form: {}
	}),
	mounted: function() {
		if (this.commandId) {
			this.form.subject = `Inviting user on command ${this.commandId}`;
		}
	},
	watch: {
		commandId: function() {
			if (this.commandId) {
				this.form.subject = `Inviting user on command ${this.commandId}`;
			}
		}
	},
	methods: {
		addContact: function() {
			AddContact(this.form).then(() => {
				this.$emit('submit');
			});
		}
	}
};
</script>

<style>
.modal-position-hight {
	border: none;
	border-radius: 0px;
	height: 70vh !important;
}
.text-addcontact {
	margin: 0 0 2px 0;
	font-size: 12px;
	color: #4a4949;
}
</style>
