var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body bg-img-white p-0",style:(("background-image: url(" + (_vm.$root.env.VUE_APP_PUBLIC_PATH) + "img/bg1.jpg)"))},[_c('div',{staticClass:"relative flex mt-2 mb-1 px-3"},[_c('t-input',{staticClass:"mr-2",attrs:{"type":"text","placeholder":"Search by name, email, phone number"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('t-button',{staticClass:"rounded-full bg-magenta",on:{"click":_vm.searchContact}},[_c('i',{staticClass:"icofont-search"})])],1),_c('div',{staticClass:"order-body px-3 pt-3"},[(_vm.contacts.length == 0)?_c('div',{staticClass:"p-4 text-center text-grey-500 shadow bg-white rounded"},[_vm._v(" No invited contact ")]):_vm._e(),_vm._l((_vm.contacts),function(contact,key){return _c('div',{key:key,staticClass:"pb-3"},[_c('button',{staticClass:"text-decoration-none text-dark text-left position-relative w-full",on:{"click":function($event){return _vm.openMessanger(contact.id)}}},[_c('div',{staticClass:"w-24 h-24 bg-cover item-img  mb-3 position-absolute rounded-full shadow-sm",staticStyle:{"left":"0px","top":"50%","border":"5px solid #fff","transform":"translate(0px, -50%)"},style:(("background-image: url(" + (_vm.$constants.STORAGE_PATH) + (contact.avatar) + ")"))}),_c('div',{staticClass:"rounded shadow-sm bg-white ",staticStyle:{"padding-left":"100px"}},[_c('div',[_c('p',{staticClass:"text-muted break-words py-3 mt-4 ml-auto "},[_c('span',{staticClass:"text-dark font-weight-bold"},[_vm._v(" "+_vm._s(contact.name)+" ")]),_c('br'),_vm._v(" "+_vm._s(contact.email)+" "),(_vm.isCommand)?_c('a',{class:[
											'btn btn-sm ml-auto cercle-contact-ajou',
											_vm.invited.includes(contact.email)
												? 'text-white bg-success'
												: ''
										],on:{"click":function($event){return _vm.addUser(contact.email)}}},[(
												_vm.invited.includes(
													contact.email
												)
											)?_c('i',{staticClass:"icofont-check-alt"}):_c('i',{staticClass:"icofont-user"})]):(
											_vm.$root.notifications.filter(
												function (el) { return el.subject ==
														("boite message privé entre " + (contact.id) + " " + _vm.userId) ||
													el.subject ==
														("boite message privé entre " + _vm.userId + " " + (contact.id)); }
											).length > 0
										)?_c('a',{staticClass:"p-2 float-right flex items-center bg-danger shadow text-white rounded-full w-16 h-6"},[_c('i',{staticClass:"icofont-notification mr-2"}),_vm._v(" "+_vm._s(_vm.$root.notifications.filter( function (el) { return el.subject == ("boite message privé entre " + (contact.id) + " " + _vm.userId) || el.subject == ("boite message privé entre " + _vm.userId + " " + (contact.id)); } ).length)+" ")]):_vm._e()])])])])])})],2)]),_c('div',{staticClass:"modal-footer p-3 border-0 flex",style:(("background-image: url(" + (_vm.$root.env.VUE_APP_PUBLIC_PATH) + "img/bg1.jpg)"))},[_c('div',{staticClass:"flex-1"},[_c('share-button',{ref:"inviteButton",attrs:{"title":"Invite a new friend","message":_vm.shareMessage,"link":_vm.registerLink}},[_vm._v(" INVITE ")])],1),(_vm.isCommand)?_c('div',{staticClass:"flex-1"},[_c('button',{staticClass:"btn bg-success text-white btn-lg btn-block rounded border-none",attrs:{"type":"button"},on:{"click":_vm.submit}},[_vm._v(" VALIDATE ")])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header",staticStyle:{"margin":"auto"}},[_c('h5',{staticClass:"modal-title"},[_vm._v("Invite")])])}]

export { render, staticRenderFns }