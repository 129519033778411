<template>
	<div class="p-3  d-flex align-items-center">
		<t-modal v-model="isModalOpen">
			<filter-form
				:cities="$root.cities"
				:countries="$root.countries"
				:tags="$root.tags"
				@setFilters="setForm"
			/>
		</t-modal>
		<div
			class="mt-3 rounded borsearch-intro bg-white shadow-sm font-weight-bold d-flex align-items-center text-decoration-none"
			style="width: 100%;"
		>
			<!-- <div
				class="input-group mt-3 rounded borsearch-intro shadow-sm overflow-hidden bg-white"
			> -->
			<div class="input-group-prepend">
				<button
					@click="setForm"
					class="border-0 btn btn-outline-secondary bg-white"
				>
					<i class="icofont-search"></i>
				</button>
			</div>
			<div style="flex: 1;">
				<input
					type="text"
					class="shadow-none border-0 form-control pl-0"
					placeholder="Restaurants search ..."
					v-model="form.keyword"
					@keyup.enter="setForm"
					aria-label=""
					aria-describedby="basic-addon1"
				/>
			</div>
			<button
				@click="openModal"
				class="border-0 btn btn-outline-secondary text-magenta bg-magenta bg-white rounded-lg m-2"
			>
				<i class="icofont-settings mr-0 text-white"></i>
			</button>
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
import FilterForm from '@/components/FilterForm';
import { ApiGetCountries, ApiGetCities, ApiGetTags } from '@/tools/apiService';
export default {
	data: () => ({
		isModalOpen: false,
		form: {
			keyword: '',
			listCitiesId: [],
			listTagsId: []
		}
	}),
	components: {
		FilterForm
	},
	mounted: function() {
		this.getInit();
	},
	methods: {
		openModal: function() {
			this.isModalOpen = true;
		},
		closeModal: function() {
			this.isModalOpen = false;
		},
		setForm: function(payload) {
			this.form.listTagsId = payload.listTagsId
				? payload.listTagsId
				: this.form.listTagsId;
			this.form.listCitiesId = payload.listCitiesId
				? payload.listCitiesId
				: this.form.listCitiesId;
			this.$router.push({
				name: 'restaurants',
				query: {
					keyword: this.form.keyword,
					cities: this.form.listCitiesId.join(','),
					tags: this.form.listTagsId.join(',')
				}
			});
			this.closeModal();
		},
		getCities: function() {
			if (this.$root.cities.length > 0) {
				return Promise.resolve();
			} else {
				return ApiGetCities().then(response => {
					this.$root.cities = response.data.cities;
				});
			}
		},
		getTags: function() {
			if (this.$root.tags.length > 0) {
				return Promise.resolve();
			} else {
				return ApiGetTags().then(response => {
					this.$root.tags = response.data.tags;
				});
			}
		},
		getCountries: function() {
			if (this.$root.countries.length > 0) {
				return Promise.resolve();
			} else {
				return ApiGetCountries().then(response => {
					this.$root.countries = response.data.countries;
				});
			}
		},
		getInit: function() {
			this.getCountries().then(() => {
				this.getCities().then(() => {
					this.getTags();
				});
			});
		}
	}
};
</script>

<style></style>
