<template>
	<div v-if="breadcrumbs.length > 1 && $route.name != 'home'">
		<br />
		<div class="breadcrumb-header">
			<div class="axe"></div>
			<button
				class="text-white font-extrabold"
				@click="
					breadcrumbs[breadcrumbs.length - 2].name == $route.name
						? $router.push({ name: 'home' })
						: $router.push({
								name: breadcrumbs[breadcrumbs.length - 2].name,
								query:
									breadcrumbs[breadcrumbs.length - 2].query,
								params:
									breadcrumbs[breadcrumbs.length - 2].params
						  })
				"
			>
				<i class="icofont-bubble-left shadow"></i>
				<span
					>Back to
					{{
						breadcrumbs[breadcrumbs.length - 2].name == $route.name
							? 'Home'
							: breadcrumbs[breadcrumbs.length - 2].title
					}}</span
				>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		breadcrumbs: function() {
			let history = this.$root.history;
			return history || [];
		}
	}
};
</script>

<style lang="scss">
.breadcrumb-header {
	.axe {
		background-color: #505050;
		width: 0.3rem;
		border-radius: 0;
		padding: 0;
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		z-index: 1000;
	}
	button {
		z-index: 10000;
		position: relative;
		// height: 1rem;
		padding: 0 1rem;
		border-radius: 50rem;
		// width: 100%;
		display: flex;
		align-items: center;
		position: fixed;
		left: -1rem;
		top: 0.5rem;
		background-color: #505050;
		span {
			// margin-left: -0.4rem;
			// border-radius: 5rem;
			// border-top-left-radius: 0;
			// border-bottom-left-radius: 0;
			// padding: 0.4rem 1rem;
		}
		i {
			// background-color: #505050;
			// width: 2rem;
			// height: 2rem;
			font-size: 2rem;
			// border-radius: 100%;
			// border-top-left-radius: 0;
			// border-bottom-left-radius: 0;
		}
	}
}
</style>
