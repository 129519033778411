var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-20"},[(_vm.role.name != 'livreur')?_c('bread-crumb'):_vm._e(),(_vm.$root.isLoading)?_c('div',{staticClass:"loading-back"},[_c('img',{staticClass:"opacity-50 w-6",attrs:{"src":require('@/assets/images/spinner.gif'),"alt":""}})]):_vm._e(),(_vm.role.name != 'livreur')?_c('search-input'):_vm._e(),_vm._t("default"),(_vm.role.name != 'livreur')?_c('div',{staticClass:"osahan-menu-fotter fixed-bottom bg-white text-center shadow-lg"},[_c('div',{staticClass:"row h-full bg-magenta"},[_c('router-link',{class:[
					'break-all text-muted col small text-decoration-none',
					['restaurants'].includes(_vm.$route.name) && _vm.$route.name
						? 'selected'
						: ''
				],attrs:{"to":{ name: 'restaurants' }}},[_c('div',{staticClass:"text-xl h-full"},[_c('img',{staticClass:"img-fluid mx-auto icons-home icons-ske relative",staticStyle:{"top":"50%","transform":"translateY(-50%)"},attrs:{"src":((_vm.$root.env.VUE_APP_PUBLIC_PATH) + "img/resteu.png"),"alt":"Responsive image"}})])]),_c('router-link',{class:[
					'break-all text-muted col small text-decoration-none',
					['table-show'].includes(_vm.$route.name) && _vm.$route.name
						? 'selected'
						: ''
				],attrs:{"to":{
					name: 'restaurants',
					query: { isReservingTable: true }
				}}},[_c('div',{staticClass:"text-xl h-full"},[_c('img',{staticClass:"img-fluid mx-auto icons-home icons-ske relative",staticStyle:{"top":"50%","transform":"translateY(-50%)"},attrs:{"src":((_vm.$root.env.VUE_APP_PUBLIC_PATH) + "img/table.png"),"alt":"Responsive image"}})])]),_c('router-link',{class:[
					'-mt-6 btn btn-sm ml-auto circle-home-button bg-magenta p-4 text-white',
					['restaurants'].includes(_vm.$route.name) && _vm.$route.name
						? 'selected'
						: ''
				],attrs:{"to":{ name: 'home' }}},[_c('div',{staticClass:"text-xl text-white"},[_c('i',{staticClass:"icofont-home "})])]),_c('router-link',{class:[
					'break-all text-muted col small text-decoration-none',
					['qrscanner'].includes(_vm.$route.name) && _vm.$route.name
						? 'selected'
						: ''
				],attrs:{"to":{ name: 'qrscanner' }}},[_c('div',{staticClass:"text-xl h-full"},[_c('img',{staticClass:"img-fluid mx-auto icons-home icons-ske relative",staticStyle:{"top":"50%","transform":"translateY(-50%)"},attrs:{"src":((_vm.$root.env.VUE_APP_PUBLIC_PATH) + "img/qrcode.png"),"alt":"Responsive image"}})])]),_c('router-link',{class:[
					'break-all text-muted col small text-decoration-none',
					['auth-login', 'user-commands'].includes(_vm.$route.name) &&
					_vm.$route.name
						? 'selected'
						: ''
				],attrs:{"to":_vm.user
						? { name: 'user-commands' }
						: { name: 'auth-login' }}},[_c('div',{staticClass:"text-xl h-full"},[_c('img',{staticClass:"img-fluid mx-auto icons-home icons-ske relative",staticStyle:{"top":"50%","transform":"translateY(-50%)"},attrs:{"src":((_vm.$root.env.VUE_APP_PUBLIC_PATH) + "img/profile.png"),"alt":"Responsive image"}})])])],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }