<template>
	<div class="mb-20">
		<bread-crumb v-if="role.name != 'livreur'"></bread-crumb>

		<div class="loading-back" v-if="$root.isLoading">
			<img
				class="opacity-50 w-6"
				:src="require('@/assets/images/spinner.gif')"
				alt=""
			/>
		</div>

		<search-input v-if="role.name != 'livreur'"></search-input>

		<slot></slot>
		<div
			v-if="role.name != 'livreur'"
			class="osahan-menu-fotter fixed-bottom bg-white text-center shadow-lg"
		>
			<div class="row h-full bg-magenta">
				<router-link
					:to="{ name: 'restaurants' }"
					:class="[
						'break-all text-muted col small text-decoration-none',
						['restaurants'].includes($route.name) && $route.name
							? 'selected'
							: ''
					]"
				>
					<div class="text-xl h-full">
						<img
							:src="
								`${$root.env.VUE_APP_PUBLIC_PATH}img/resteu.png`
							"
							class="img-fluid mx-auto icons-home icons-ske relative"
							style="top: 50%; transform: translateY(-50%)"
							alt="Responsive image"
						/>
					</div>
				</router-link>

				<router-link
					:to="{
						name: 'restaurants',
						query: { isReservingTable: true }
					}"
					:class="[
						'break-all text-muted col small text-decoration-none',
						['table-show'].includes($route.name) && $route.name
							? 'selected'
							: ''
					]"
				>
					<div class="text-xl h-full">
						<img
							:src="
								`${$root.env.VUE_APP_PUBLIC_PATH}img/table.png`
							"
							class="img-fluid mx-auto icons-home icons-ske relative"
							style="top: 50%; transform: translateY(-50%)"
							alt="Responsive image"
						/>
					</div>
				</router-link>

				<router-link
					:to="{ name: 'home' }"
					:class="[
						'-mt-6 btn btn-sm ml-auto circle-home-button bg-magenta p-4 text-white',
						['restaurants'].includes($route.name) && $route.name
							? 'selected'
							: ''
					]"
				>
					<div class="text-xl text-white">
						<i class="icofont-home "></i>
					</div>
				</router-link>

				<router-link
					:to="{ name: 'qrscanner' }"
					:class="[
						'break-all text-muted col small text-decoration-none',
						['qrscanner'].includes($route.name) && $route.name
							? 'selected'
							: ''
					]"
				>
					<div class="text-xl h-full">
						<img
							:src="
								`${$root.env.VUE_APP_PUBLIC_PATH}img/qrcode.png`
							"
							class="img-fluid mx-auto icons-home icons-ske relative"
							style="top: 50%; transform: translateY(-50%)"
							alt="Responsive image"
						/>
					</div>
				</router-link>

				<router-link
					:to="
						user
							? { name: 'user-commands' }
							: { name: 'auth-login' }
					"
					:class="[
						'break-all text-muted col small text-decoration-none',
						['auth-login', 'user-commands'].includes($route.name) &&
						$route.name
							? 'selected'
							: ''
					]"
				>
					<div class="text-xl h-full">
						<img
							:src="
								`${$root.env.VUE_APP_PUBLIC_PATH}img/profile.png`
							"
							class="img-fluid mx-auto icons-home icons-ske relative"
							style="top: 50%; transform: translateY(-50%)"
							alt="Responsive image"
						/>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchInput from '@/components/SearchInput';
import BreadCrumb from '@/components/BreadCrumb';
export default {
	components: { SearchInput, BreadCrumb },
	data: () => ({}),
	methods: {},
	computed: {
		...mapGetters({
			user: 'auth/user',
			role: 'auth/role'
		})
	}
};
</script>

<style lang="scss" scoped>
.circle-home-button {
	border: 5px solid #f1f2f5;
	color: #fff;
	width: 50px;
	height: 50px;
	border-radius: 50px !important;
}
.icons-home {
	font-size: 24px;
	line-height: 2;
}
.icons-intro {
	color: #c20016;
	font-size: 63px;
}
.borsearch-intro {
	border: 1px solid #9e9e9e;
}
.icons-ske {
	width: 1.8rem;
}
</style>
