<template>
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header" style="margin:auto;">
				<h5 class="modal-title">Invite</h5>
			</div>

			<div
				class="modal-body bg-img-white p-0"
				:style="
					`background-image: url(${$root.env.VUE_APP_PUBLIC_PATH}img/bg1.jpg)`
				"
			>
				<div class="relative flex mt-2 mb-1 px-3">
					<t-input
						type="text"
						class="mr-2"
						v-model="keyword"
						placeholder="Search by name, email, phone number"
					/>
					<t-button
						@click="searchContact"
						class="rounded-full bg-magenta"
					>
						<i class="icofont-search" />
					</t-button>
				</div>
				<div class="order-body px-3 pt-3">
					<div
						v-if="contacts.length == 0"
						class="p-4 text-center text-grey-500 shadow bg-white rounded"
					>
						No invited contact
					</div>
					<div
						class="pb-3"
						v-for="(contact, key) in contacts"
						:key="key"
					>
						<button
							@click="openMessanger(contact.id)"
							class="text-decoration-none text-dark text-left position-relative w-full"
						>
							<div
								:style="
									`background-image: url(${$constants.STORAGE_PATH}${contact.avatar})`
								"
								class="w-24 h-24 bg-cover item-img  mb-3 position-absolute rounded-full shadow-sm"
								style="left: 0px;top: 50%;border: 5px solid #fff;transform: translate(0px, -50%);"
							/>
							<div
								class="rounded shadow-sm bg-white "
								style="padding-left: 100px;"
							>
								<div>
									<p
										class="text-muted break-words py-3 mt-4 ml-auto "
									>
										<span
											class="text-dark font-weight-bold"
										>
											{{ contact.name }}
										</span>
										<br />
										{{ contact.email }}

										<a
											v-if="isCommand"
											@click="addUser(contact.email)"
											:class="[
												'btn btn-sm ml-auto cercle-contact-ajou',
												invited.includes(contact.email)
													? 'text-white bg-success'
													: ''
											]"
										>
											<i
												v-if="
													invited.includes(
														contact.email
													)
												"
												class="icofont-check-alt"
											></i>
											<i v-else class="icofont-user"></i>
										</a>
										<a
											v-else-if="
												$root.notifications.filter(
													el =>
														el.subject ==
															`boite message privé entre ${contact.id} ${userId}` ||
														el.subject ==
															`boite message privé entre ${userId} ${contact.id}`
												).length > 0
											"
											class="p-2 float-right flex items-center bg-danger shadow text-white rounded-full w-16 h-6"
										>
											<i
												class="icofont-notification mr-2"
											/>
											{{
												$root.notifications.filter(
													el =>
														el.subject ==
															`boite message privé entre ${contact.id} ${userId}` ||
														el.subject ==
															`boite message privé entre ${userId} ${contact.id}`
												).length
											}}
										</a>
									</p>
								</div>
							</div>
						</button>
					</div>
				</div>
			</div>
			<div
				class="modal-footer p-3 border-0 flex"
				:style="
					`background-image: url(${$root.env.VUE_APP_PUBLIC_PATH}img/bg1.jpg)`
				"
			>
				<div class="flex-1">
					<share-button
						ref="inviteButton"
						title="Invite a new friend"
						:message="shareMessage"
						:link="registerLink"
					>
						INVITE
					</share-button>
				</div>
				<div class="flex-1" v-if="isCommand">
					<button
						@click="submit"
						type="button"
						class="btn bg-success text-white btn-lg btn-block rounded border-none"
					>
						VALIDATE
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	ApiGetContacts,
	ApiUpdateNotifications,
	ApiSearchContacts
} from '@/tools/apiService';
import { formatDate } from '@/tools/helpers';
export default {
	props: ['isCommand', 'userId', 'userName', 'restaurantName', 'planifiedAt'],
	data: () => ({
		keyword: null,
		contacts: [],
		invited: []
	}),
	mounted: function() {
		this.getContacts();
	},
	computed: {
		shareMessage: function() {
			let message = `${this.userName} would like to invite you to download Chill Order App, for a better service experience`;
			if (this.isCommand) {
				message = `${
					this.userName
				} would like to invite you to join his table at the restaurant ${
					this.restaurantName
				}, the ${formatDate(this.planifiedAt)}`;
			}
			return message;
		},
		registerLink: function() {
			let route = this.$router.resolve({
				name: 'auth-signup',
				query: { referal: this.userId }
			});

			return `${process.env.VUE_APP_URL}${route.resolved.fullPath}`;
		}
	},
	methods: {
		addUser: function(email) {
			if (this.invited.includes(email))
				this.invited = this.invited.filter(el => el != email);
			else this.invited.push(email);
		},
		searchContact: function() {
			ApiSearchContacts(this.keyword).then(response => {
				this.keyword = null;

				let contactList = [...response.data.contacts, ...this.contacts];
				let contactListId = [
					...new Set(
						contactList
							.map(el => el.id)
							.filter(el => el != this.userId)
					)
				];

				this.contacts = contactListId.map(el =>
					contactList.find(subel => subel.id == el)
				);

				if (response.data.contacts.length == 0) {
					this.$dialog
						.confirm({
							title: 'Invite a friend ?',
							text:
								'There is no contact with the informations you provided, you want to invite a friend from your contacts'
						})
						.then(response => {
							if (response.isOk) {
								this.$refs.inviteButton.open();
							}
						});
				} else {
					this.$notify.success({
						title: `${response.data.contacts.length} contact added`,
						position: 'top center'
					});
				}
			});
		},
		getContacts: function() {
			ApiGetContacts().then(response => {
				this.contacts = response.data.contacts;
			});
		},
		setSeenNotifications: function(idList) {
			ApiUpdateNotifications({ idList }).then(() => {
				this.$root.notifications = this.$root.notifications.map(el => {
					if (idList.includes(el.id)) el.seen = true;
					return el;
				});
				this.$forceUpdate();
			});
		},
		openMessanger: function(userId) {
			let contact = this.contacts.find(el => el.id == userId);
			if (contact) this.addUser(contact.email);
			this.$emit('open-messanger', userId);
		},
		submit: function() {
			this.$emit('invited', this.invited);
		}
	}
};
</script>

<style>
.modal-content {
	border: none;
	border-radius: 0px;
	height: 90vh;
}
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #c20016;
	background-color: #c20016;
}

.cercle-contact-ajou {
	position: absolute;
	top: 50%;
	right: 3%;
	border: 3px solid #f1f2f5;
	width: 29px;

	height: 29px;
	background: #ececec;
	border-radius: 50px !important;
	color: #fff;
	font-size: 17px;
	box-shadow: 0px 0px 3px #dedede;
	line-height: 39px;
	margin: auto;
	text-align: center;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate(0px, -50%);
}

.bg-img-white {
	background-size: cover;
}
</style>
