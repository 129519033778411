<template>
	<div>
		<div class="my-5 py-1 px-3 text-center">
			<img
				:src="`${$root.env.VUE_APP_PUBLIC_PATH}img/big-star.png`"
				class="img-fluid mx-auto icons-home icons-ske"
				alt="Responsive image"
				style="    width: 79%;"
			/>
			<!-- <h4>Lorem ipsum dolor sit amet,</h4> -->
			<!-- <p>24 July 2021</p> -->
			<div class="p-2 bg-white shadow-sm">
				<p class="text-muted font-star m-0">
					<i
						v-for="(i, key) in Array(5).fill()"
						:key="key"
						@click="setRate(key + 1)"
						:class="[
							'icofont-star',
							form.rate > key
								? 'text-yellow-400'
								: 'text-grey-300'
						]"
					></i>
				</p>
			</div>

			<div class="pt-3">
				<div
					class="input-group mb-3 rounded shadow-sm overflow-hidden bg-white"
				>
					<textarea
						type="text"
						class="shadow-none border-0 form-control form-control-lg p-3 min-h-32"
						placeholder="Add a comment"
						v-model="form.comment"
						aria-describedby="basic-addon1"
					>
						Add a comment

					</textarea
					>
				</div>
			</div>
			<button
				@click="submit"
				type="button"
				class="btn bg-magenta text-white btn-lg btn-block"
			>
				A J O U T E R
			</button>
		</div>
	</div>
</template>
<script>
import { ApiAddRestaurantReview } from '@/tools/apiService';
export default {
	props: ['restaurantId'],
	data: () => ({
		form: {
			rate: 0,
			comment: ''
		}
	}),
	methods: {
		submit: function() {
			this.form.comment = this.form.comment ? this.form.comment : ' --- ';
			ApiAddRestaurantReview(this.restaurantId, this.form).then(() => {
				this.$notify.success({
					title: 'Your demand was successful',
					position: 'top center'
				});
				this.$emit('submit');
			});
		},
		setRate: function(rate) {
			this.form.rate = rate;
		}
	}
};
</script>
<style scoped>
.font-review {
	font-size: 16px;
}
.font-star {
	font-size: 36px;
}
</style>
