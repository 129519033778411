var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.breadcrumbs.length > 1 && _vm.$route.name != 'home')?_c('div',[_c('br'),_c('div',{staticClass:"breadcrumb-header"},[_c('div',{staticClass:"axe"}),_c('button',{staticClass:"text-white font-extrabold",on:{"click":function($event){_vm.breadcrumbs[_vm.breadcrumbs.length - 2].name == _vm.$route.name
					? _vm.$router.push({ name: 'home' })
					: _vm.$router.push({
							name: _vm.breadcrumbs[_vm.breadcrumbs.length - 2].name,
							query:
								_vm.breadcrumbs[_vm.breadcrumbs.length - 2].query,
							params:
								_vm.breadcrumbs[_vm.breadcrumbs.length - 2].params
					  })}}},[_c('i',{staticClass:"icofont-bubble-left shadow"}),_c('span',[_vm._v("Back to "+_vm._s(_vm.breadcrumbs[_vm.breadcrumbs.length - 2].name == _vm.$route.name ? 'Home' : _vm.breadcrumbs[_vm.breadcrumbs.length - 2].title))])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }