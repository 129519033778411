<template>
	<skeleton>
		<div>
			<t-modal v-model="isRestaurantOpen">
				<div class="py-2">
					<restaurant-review
						v-if="selectedRestaurantId"
						:restaurantId="selectedRestaurantId"
						@submit="closeRestaurantReview"
					/>
				</div>
			</t-modal>
			<t-modal v-model="isNotificationModalOpen">
				<div
					class="container flex mx-auto w-full items-center justify-center pt-3"
				>
					<div
						class="flex items-center justify-center w-full text-grey-500 h-32 mb-3 bg-white shadow rounded"
						v-if="otherNotifications.length == 0"
					>
						Aucune notification
					</div>
					<ul class="flex flex-col mb-0">
						<li
							v-for="(notification, key) in otherNotifications"
							:key="key"
							:class="[
								'border-grey-400 flex flex-row mb-3',
								notification.seen ? 'opacity-75' : ''
							]"
							@click="notificationClicked(notification)"
						>
							<div
								class="rounded select-none cursor-pointer bg-grey-200 rounded-md flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
							>
								<div class="flex-1 pl-1 mr-16">
									<div class="font-medium">
										{{ notification.subject }}
									</div>
									<div class="text-grey-600 text-sm">
										{{ notification.message }}
									</div>
								</div>
								<div class="text-grey-600 text-xs">
									{{
										timeSince(
											new Date(notification.created_at.replace(/-/g, '/'))
										)
									}}
								</div>
							</div>
						</li>
					</ul>
				</div>
			</t-modal>
			<t-modal v-model="isMessangerModalOpen">
				<messanger
					v-if="receiverId"
					:senderId="user.id"
					:receiverId="receiverId"
				/>
			</t-modal>
			<t-modal v-model="isUpdateProfileModalOpen">
				<update-profile
					@submit="closeUpdateProfileModal"
					@cancel="closeUpdateProfileModal"
					:withCancel="true"
				/>
			</t-modal>
			<t-modal v-model="isContactModalOpen">
				<list-contact
					:userId="user.id"
					:userName="user ? user.name : null"
					@add="openAddingContactModal"
					@open-messanger="setReceiver"
				/>
			</t-modal>
			<t-modal v-model="isAddingContactModalOpen">
				<add-contact
					@submit="openContactModal"
					@back="openContactModal"
				/>
			</t-modal>
			<div class="osahan-account">
				<div class="p-3 border-bottom flex items-center">
					<div class="flex-1 font-weight-bold m-0 text-4">
						My profil
					</div>
					<a
						@click="logoutUser"
						class="text-dark mx-1 my-2 inline-block bg-light rounded-pill p-1 px-3 border shadow-sm"
					>
						<span>
							<i class="icofont-logout icofont-size" />
							logout
						</span>
					</a>
				</div>
				<div class="profile text-center ">
					<div class="flex items-center m-2">
						<div class="w-40 pt-4">
							<div class="relative m-auto">
								<div
									:style="
										`background-image: url(${
											role.name == 'livreur'
												? waiterAvatar
												: `${$constants.STORAGE_PATH}${user.avatar}`
										})`
									"
									class="bg-cover w-20 h-20 m-auto shadow rounded-pill relative"
								>
									<a
										@click="openUpdateProfileModal"
										class="text-dark -mb-1 -mr-1 absolute bottom-0 right-0"
									>
										<i
											class="icofont-edit-alt bg-light rounded-pill p-2 icofont-size border shadow-sm"
										/>
									</a>
								</div>
								<h6 class="font-weight-bold m-0 mt-2">
									{{ user.name }}
								</h6>
								<p class="small text-muted">{{ user.email }}</p>
							</div>
						</div>
						<div class="flex-1 p-2">
							<div class="mb-3 -mx-1">
								<a
									@click="openNotificationModal"
									:class="[
										'mx-1 block rounded-pill p-2 border shadow-sm my-2',
										!otherNotifications.filter(
											el => !el.seen
										) ||
										otherNotifications.filter(
											el => !el.seen
										).length == 0
											? 'bg-light text-dark'
											: 'bg-danger text-white'
									]"
								>
									<span
										v-if="
											!otherNotifications.filter(
												el => !el.seen
											) ||
												otherNotifications.filter(
													el => !el.seen
												).length == 0
										"
									>
										<i
											class="icofont-notification icofont-size"
										/>
										notifications
									</span>
									<div v-else>
										<i class="icofont-notification mr-2" />
										{{
											otherNotifications.filter(
												el => !el.seen
											).length
										}}
									</div>
								</a>
								<a
									v-if="role.name != 'livreur'"
									@click="openContactModal"
									:class="[
										'mx-1 block bg-light rounded-pill p-2 border shadow-sm my-2',
										!notifications ||
										notifications.length == 0
											? 'bg-light text-dark'
											: 'bg-danger text-white'
									]"
								>
									<span
										v-if="
											!notifications ||
												notifications.length == 0
										"
									>
										<i
											class="icofont-contacts icofont-size"
										/>
										contacts
									</span>
									<div v-else>
										<i class="icofont-contacts mr-2" />
										{{ notifications.length }}
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="order-menu">
					<div class="row m-0 text-center">
						<div v-if="role.name != 'livreur'" :class="['col']">
							<a
								@click="switchTab('commands')"
								:class="[
									'text-muted text-decoration-none h-full',
									tab == 'commands' ? 'font-weight-bold' : ''
								]"
							>
								<div
									:class="[
										'text-magenta text-xl py-2 cursor-pointer h-full m-auto rounded border-magenta-900 border-solid border-1',
										tab == 'commands'
											? 'bg-magenta text-white'
											: 'bg-grey-100'
									]"
								>
									<i class="icofont-restaurant"></i>
									<div class="text-xs">
										Orders
									</div>
								</div>
							</a>
						</div>
						<div v-if="role.name != 'livreur'" :class="['col']">
							<a
								@click="switchTab('favorites')"
								:class="[
									'text-muted text-decoration-none h-full',
									tab == 'favorites' ? 'font-weight-bold' : ''
								]"
							>
								<div
									:class="[
										'text-magenta text-xl py-2 cursor-pointer h-full m-auto rounded border-magenta-900 border-solid border-1',
										tab == 'favorites'
											? 'bg-magenta text-white'
											: 'bg-grey-100'
									]"
								>
									<i class="icofont-heart-alt"></i>
									<div class="text-xs">
										Favorites
									</div>
								</div>
							</a>
						</div>
						<div v-else :class="['col']">
							<a
								@click="switchTab('my-commands')"
								:class="[
									'text-muted text-decoration-none h-full',
									tab == 'my-commands'
										? 'font-weight-bold'
										: ''
								]"
							>
								<div
									:class="[
										'text-magenta text-xl py-2 cursor-pointer h-full m-auto rounded border-magenta-900 border-solid border-1',
										tab == 'my-commands'
											? 'bg-magenta text-white'
											: 'bg-grey-100'
									]"
								>
									<i class="icofont-hour-glass"></i>
									<div class="text-xs">
										My orders
									</div>
								</div>
							</a>
						</div>
						<div :class="['col']">
							<a
								@click="switchTab('history')"
								:class="[
									'text-muted text-decoration-none h-full',
									tab == 'history' ? 'font-weight-bold' : ''
								]"
							>
								<div
									:class="[
										'text-magenta text-xl py-2 cursor-pointer h-full m-auto rounded border-magenta-900 border-solid border-1',
										tab == 'history'
											? 'bg-magenta text-white'
											: 'bg-grey-100'
									]"
								>
									<i class="icofont-clock-time"></i>
									<div class="text-xs">
										Historic
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<hr />
				<router-view></router-view>
			</div>
		</div>
	</skeleton>
</template>

<script>
import Echo from 'laravel-echo';
import {
	ApiGetNotifications,
	ApiSetUserOnline,
	ApiUpdateNotifications
} from '@/tools/apiService';
import { timeSince } from '@/tools/helpers';
import ListContact from '@/components/ListContact';
import AddContact from '@/components/AddContact';
import Skeleton from './components/Skeleton';
import RestaurantReview from '../views/restaurants/component/RestaurantReview.vue';
import Messanger from '@/components/Messanger';
import { mapGetters, mapActions } from 'vuex';
import UpdateProfile from '../views/profile/component/UpdateProfile.vue';
export default {
	data: () => ({
		allNotifications: [],
		reloadCounter: 0,
		waiterAvatar: require('@/assets/images/waiter.png'),
		tab: 'commands',
		receiverId: null,
		isRestaurantOpen: false,
		isNotificationModalOpen: false,
		isUpdateProfileModalOpen: false,
		isMessangerModalOpen: false,
		isContactModalOpen: false,
		isAddingContactModalOpen: false
	}),
	components: {
		RestaurantReview,
		Skeleton,
		ListContact,
		AddContact,
		UpdateProfile,
		Messanger
	},
	mounted: function() {
		if (!this.user) this.$router.push({ name: 'home' });
		let element = document.body;
		if (this.$root.isInsideIframe) {
			element = document.getElementsByTagName('iframe')[0].contentDocument
				.body;
		}
		element.style.backgroundImage = `url("${require('@/assets/images/bg-Review.jpg')}")`;
		this.getNotifications();
		setTimeout(() => {
			this.initWebSocket();
		}, 1000);
	},
	watch: {
		token: function() {
			this.initWebSocket();
		},
		isRestaurantOpen: function(newVal) {
			if (!newVal) this.closeRestaurantReview();
		},
		isRestaurantDialogOpen: function(newVal) {
			this.isRestaurantOpen = newVal;
		}
	},
	methods: {
		timeSince,
		...mapActions({
			logout: 'auth/logout',
			refreshCart: 'cart/refreshCart',
			openRestaurantReview: 'notification/openRestaurantReview',
			closeRestaurantReview: 'notification/closeRestaurantReview'
		}),
		initOnlineChannel: function() {
			this.$root.echo.channel(`presence-message.online`).unbind();

			setTimeout(() => {
				this.$root.echo
					.channel(`presence-message.online`)
					.joining(user => {
						ApiSetUserOnline(user.id, { isOnline: true });
					})
					.leaving(user => {
						ApiSetUserOnline(user.id, { isOnline: false });
					});
			}, 500);
		},
		notificationClicked: function(notification) {
			if (notification.subject.startsWith('boite rating ')) {
				let restaurantId = notification.subject.split(
					'boite rating '
				)[1];
				this.isNotificationModalOpen = false;
				this.openRestaurantReview(restaurantId);
			}
		},
		initWebSocket: function() {
			this.$root.echo = new Echo({
				broadcaster: 'socket.io',
				authEndpoint: '/broadcasting/auth',
				key: process.env.VUE_APP_NOTIFICATION_APP_ID,
				host: process.env.VUE_APP_NOTIFICATION_URL,
				auth: { headers: { Authorization: this.token } }
			});
			this.listenNotifications();
			this.initOnlineChannel();
		},
		listenNotifications: function() {
			this.$root.echo
				.channel(`presence-message.new-notification`)
				.unbind();
			setTimeout(() => {
				this.$root.echo
					.channel(`presence-message.new-notification`)
					.listen('NewNotification', () => {
						this.getNotifications();
					});
			}, 500);
		},
		logoutUser: function() {
			this.logout();
			this.refreshCart();
			this.$router.push({
				name: 'auth-login'
			});
		},
		switchTab: function(tab) {
			let query = {};
			this.tab = tab;
			this.reloadCounter = this.reloadCounter + 1;
			query.reload = this.reloadCounter;
			if (this.tab == 'my-commands') query.isActive = true;
			if (this.tab == 'history') query.isHistory = true;
			this.$router.push({ name: `user-${tab}`, query });
		},
		setReceiver: function(receiverId) {
			this.receiverId = receiverId;
			this.openMessangerModal();
		},
		setSeenNotifications: function(idList) {
			ApiUpdateNotifications({ idList }).then(() => {
				this.$root.notifications = this.$root.notifications.map(el => {
					if (idList.includes(el.id)) el.seen = true;
					return el;
				});
			});
		},
		closeUpdateProfileModal: function() {
			this.isUpdateProfileModalOpen = false;
		},
		openUpdateProfileModal: function() {
			this.isAddingContactModalOpen = false;
			this.isContactModalOpen = false;
			this.isMessangerModalOpen = false;
			this.isNotificationModalOpen = false;
			this.isUpdateProfileModalOpen = true;
		},
		openMessangerModal: function() {
			this.isAddingContactModalOpen = false;
			this.isContactModalOpen = false;
			this.isUpdateProfileModalOpen = false;
			this.isNotificationModalOpen = false;
			this.isMessangerModalOpen = true;
		},
		openAddingContactModal: function() {
			this.isAddingContactModalOpen = true;
			this.isContactModalOpen = false;
			this.isUpdateProfileModalOpen = false;
			this.isNotificationModalOpen = false;
			this.isMessangerModalOpen = false;
		},
		openContactModal: function() {
			this.isMessangerModalOpen = false;
			this.isUpdateProfileModalOpen = false;
			this.isAddingContactModalOpen = false;
			this.isNotificationModalOpen = false;
			this.isContactModalOpen = true;
		},
		openNotificationModal: function() {
			this.isMessangerModalOpen = false;
			this.isUpdateProfileModalOpen = false;
			this.isAddingContactModalOpen = false;
			this.isNotificationModalOpen = true;
			this.isContactModalOpen = false;
			setTimeout(() => {
				if (this.otherNotifications.map(el => el.id).length > 0) {
					this.setSeenNotifications(
						this.otherNotifications.map(el => el.id)
					);
				}
			}, 1000);
		},
		ringBell: function() {
			let bellSound = require('@/assets/sounds/bell-ringing-04.mp3');
			new Audio(bellSound).play();
		},
		getNotifications: function() {
			ApiGetNotifications({
				seen: null
			}).then(response => {
				let existenIds = this.$root.notifications.map(el => el.id);
				let notSeenMessages = response.data.messages.filter(
					el => !el.seen
				);

				this.allNotifications = response.data.messages;

				let isNew = notSeenMessages
					.map(el => el.id)
					.some(el => !existenIds.includes(el));

				if (
					isNew &&
					this.role.name == 'livreur' &&
					notSeenMessages.length > 0
				)
					this.ringBell();

				this.$root.notifications = notSeenMessages;
			});
		}
	},
	beforeDestroy() {
		this.$root.echo.channel(`presence-message.new-notification`).unbind();
		this.$root.echo.channel(`presence-message.online`).unbind();
	},
	computed: {
		...mapGetters({
			token: 'auth/token',
			user: 'auth/user',
			role: 'auth/role',
			isRestaurantDialogOpen: 'notification/isRestaurantDialogOpen',
			selectedRestaurantId: 'notification/selectedRestaurantId'
		}),
		notifications: function() {
			return this.$root.notifications.filter(el =>
				el.subject.startsWith(`boite message privé entre `)
			);
		},
		otherNotifications: function() {
			return this.allNotifications.filter(
				el =>
					!el.subject.startsWith(`boite message privé `) &&
					!el.subject.startsWith(`boite message pour la commande `)
			);
		}
	}
};
</script>
