<template>
	<div>
		<div
			v-if="notice"
			class="w-full px-4 py-2 text-center bg-yellow-600 text-white mb-2"
		>
			{{ notice }}
		</div>
		<div
			class="flex flex-row h-screen antialiase text-gray-800"
			style="height: 90vh;"
		>
			<div class="flex flex-col h-full w-full bg-white relative">
				<div class="h-full overflow-hidden">
					<div class="h-full overflow-y-auto">
						<div class="grid grid-cols-12 gap-y-2">
							<div
								v-for="(message, key) in messages"
								:key="key"
								:class="[
									'message-text',
									message.userId != senderId
										? 'col-start-1 col-end-11 p-2 rounded-2xl'
										: 'col-start-2 col-end-13 p-2 rounded-2xl'
								]"
							>
								<div
									:class="[
										message.userId != senderId
											? 'flex flex-row items-start'
											: 'flex items-start justify-start flex-row-reverse'
									]"
								>
									<div>
										<div
											class="flex items-center justify-center h-10 w-10 rounded-full text-white flex-shrink-0 mx-2 bg-cover bg-no-repeat"
											:style="
												`background-color: ${letterColor(
													message.owner.name
												)}; background-image: url(${
													$constants.STORAGE_PATH
												}${message.owner.avatar})`
											"
										>
											<span
												v-if="!message.owner.avatar"
												class="uppercase"
												>{{
													message.owner.name.charAt(0)
												}}</span
											>
										</div>
										<div
											v-if="message.userId != senderId"
											class="text-xs text-grey-400 w-10 m-auto text-center"
										>
											{{ message.owner.name }}
										</div>
									</div>
									<div class="relative">
										<div
											:class="[
												'text-sm py-2 px-4 shadow rounded-2xl',
												message.userId != senderId
													? 'bg-white'
													: 'bg-indigo-100'
											]"
										>
											{{ message.message }}
										</div>
										<small
											v-if="message.seen"
											:class="[
												'ml-2 text-grey-400 my-1',
												message.userId != senderId
													? 'hidden'
													: 'float-right'
											]"
										>
											<i
												class="icofont-check opacity-75"
											></i
											>vue
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="flex flex-row items-stretch sticky bottom-0 bg-white w-full shadow px-2 py-2"
				>
					<div class="p-1 px-2">
						<button
							@click="sendHi"
							class="flex items-center justify-center text-6 rounded-full bg-gray-200 text-white shadow-md h-10 w-10 hover:bg-gray-300"
						>
							👋
						</button>
					</div>
					<div class="flex flex-row items-center w-full rounded h-12">
						<input
							type="text"
							class="rounded-full border border-transparent w-full focus:outline-none text-sm h-10 flex items-center"
							@keydown.enter="addMessage"
							v-model="form.message"
							placeholder="Type your message...."
						/>
					</div>
					<div class="p-1 px-2">
						<button
							@click="addMessage"
							class="flex items-center justify-center text-6 rounded-full bg-gray-200 text-white shadow-md h-10 w-10 hover:bg-gray-300 bg-success"
						>
							<i class="icofont-send-mail"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
let slugify = require('slugify');
import { ApiGetMessages, ApiAddMessage } from '@/tools/apiService';
import { letterColor } from '@/tools/helpers';
export default {
	props: ['senderId', 'receiverId', 'commandId', 'notice'],
	data: () => ({
		polling: null,
		subjects: [],
		form: {
			message: ''
		},
		messages: []
	}),
	mounted: function() {
		this.subjects = this.commandId
			? [`boite message pour la commande ${this.commandId}`]
			: [
					`boite message privé entre ${this.senderId} ${this.receiverId}`,
					`boite message privé entre ${this.receiverId} ${this.senderId}`
			  ];
		this.getMessages();
		this.listenMessages();
	},
	beforeDestroy() {
		this.subjects.forEach(subject => {
			const element = slugify(subject);
			this.$root.echo.channel(`presence-message.${element}`).unbind();
		})
		clearInterval(this.polling);
	},
	methods: {
		letterColor,
		scrollToView: function() {
			let messagesEl = [];
			if (this.$root.isInsideIframe) {
				messagesEl = document
					.getElementsByTagName('iframe')[0]
					.contentDocument.querySelectorAll('.message-text');
			} else {
				messagesEl = document.querySelectorAll('.message-text');
			}
			messagesEl[messagesEl.length - 1].scrollIntoView({
				behavior: 'smooth'
			});
		},
		getMessages: function(withoutLoader = false) {
			ApiGetMessages(this.subjects.join(','), withoutLoader).then(
				response => {
					if (this.messages.length != response.data.messages.length) {
						this.messages = response.data.messages;
						setTimeout(() => {
							this.scrollToView();
						}, 100);
					} else {
						this.messages = response.data.messages;
					}
				}
			);
		},
		listenMessages: function() {
			this.subjects.forEach(subject => {
				const element = slugify(subject);
				this.$root.echo.channel(`presence-message.${element}`).unbind();
				setTimeout(() => {
					this.$root.echo
						.channel(`presence-message.${element}`)
						.listen('NewMessageNotification', () => {
							this.getMessages(true);
						});
				}, 500);
			})
		},
		sendHi: function() {
			ApiAddMessage({
				subject: this.subjects[0],
				message: '👋',
				recevierId: this.receiverId
			}).then(() => {
				this.getMessages(false);
			});
		},
		addMessage: function() {
			ApiAddMessage({
				subject: this.subjects[0],
				message: this.form.message,
				recevierId: this.receiverId
			}).then(() => {
				this.form.message = '';
			});
		}
	}
};
</script>

<style></style>
