<template>
	<div class="rounded">
		<div class="p-0 rounded">
			<div class="osahan-filter rounded">
				<div class="filter rounded">
					<!-- Filter -->
					<div class="p-3 bg-light border-bottom rounded">
						<h6 class="m-0">Cities</h6>
					</div>
					<div
						v-for="(city, key) in cities"
						:key="`city-${key}`"
						class="custom-control border-bottom px-0  custom-checkbox"
					>
						<input
							type="checkbox"
							class="custom-control-input"
							:id="`city-${city.id}`"
							v-model="form.listCitiesId"
							:value="city.id"
						/>
						<label
							class="custom-control-label py-3 w-100 px-3"
							:for="`city-${city.id}`"
							>{{ city.label }}
						</label>
					</div>
					<!-- Filter -->

					<!-- Filter -->
					<div class="p-3 bg-light border-bottom">
						<h6 class="m-0">Categories</h6>
					</div>
					<div
						v-for="(tag, key) in tags"
						:key="`tags-${key}`"
						class="custom-control border-bottom px-0  custom-checkbox"
					>
						<input
							type="checkbox"
							class="custom-control-input"
							:id="`tag-${tag.id}`"
							v-model="form.listTagsId"
							:value="tag.id"
						/>
						<label
							class="custom-control-label py-3 w-100 px-3"
							:for="`tag-${tag.id}`"
							>{{ tag.label }}</label
						>
					</div>
				</div>
			</div>
		</div>
		<div class="p-2 border-0">
			<div class="col-12 m-0 p-0">
				<button
					@click="submit"
					type="button"
					class="btn bg-magenta text-white btn-lg btn-block"
				>
					Apply
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['cities', 'countries', 'tags'],
	data: () => ({
		form: {
			listCitiesId: [],
			listTagsId: []
		}
	}),
	methods: {
		submit: function() {
			this.$root.isLoading = true;
			setTimeout(() => {
				this.$root.isLoading = false;
				this.$emit('setFilters', this.form);
			}, 1000);
		}
	}
};
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #c20016;
	background-color: #c20016;
}
</style>
