<template>
	<div>
		<div slot="top-right">
			<button class="modal-close" @click="cancel">
				<i class="fas fa-times"></i>
			</button>
		</div>
		<form class="p-4" @input="handleInput" autocomplete="off">
			<div class="form-row">
				<div class="w-full my-1">
					<file-upload v-model="form.avatar"></file-upload>
					<span class="text-danger pr-2" v-if="$root.errors.avatar">
						{{ $root.errors.avatar.join(',') }}
					</span>
				</div>
				<div class="w-full my-1">
					<div class="flex w-full">
						<vue-country-code
							ref="vcc"
							class="mr-1 transition duration-100 ease-in-out border rounded focus:ring-2 disabled:opacity-50 disabled:cursor-not-allowed text-black placeholder-gray-400 bg-white border-gray-300 text-sm"
							@onSelect="
								({ dialCode }) => (form.dialCode = dialCode)
							"
						/>
						<t-input
							type="text"
							v-model="form.phone"
							class="form-control p-4"
							placeholder="Your phone number"
						/>
					</div>
					<span class="text-danger pr-2" v-if="$root.errors.phone">
						{{ $root.errors.phone.join(',') }}
					</span>
				</div>
				<div class="w-full my-1">
					<t-input
						type="text"
						v-model="form.name"
						class="form-control p-4"
						placeholder="Your name"
					/>
					<span class="text-danger pr-2" v-if="$root.errors.name">
						{{ $root.errors.name.join(',') }}
					</span>
				</div>
				<div class="w-full my-1">
					<t-input
						type="text"
						v-model="form.email"
						class="form-control p-4"
						placeholder="Your E-mail address"
					/>
					<span class="text-danger pr-2" v-if="$root.errors.email">
						{{ $root.errors.email.join(',') }}
					</span>
				</div>
				<div class="w-full my-1">
					<t-input
						type="password"
						v-model="form.password"
						class="form-control p-4"
						placeholder="New password"
					/>
					<span class="text-danger pr-2" v-if="$root.errors.password">
						{{ $root.errors.password.join(',') }}
					</span>
				</div>
				<div class="w-full my-1">
					<t-input
						type="password"
						v-model="form.password_confirmation"
						class="form-control p-4"
						placeholder="Confirm password"
					/>
					<span
						class="text-danger pr-2"
						v-if="$root.errors.password_confirmation"
					>
						{{ $root.errors.password_confirmation.join(',') }}
					</span>
				</div>
			</div>
			<br />
			<div class="flex -m-2">
				<t-button
					@click="updateUser"
					type="button"
					class="flex-1 bg-success m-2"
				>
					EDIT
				</t-button>
				<t-button
					v-if="withCancel"
					@click="cancel"
					type="button"
					class="flex-1 bg-dark m-2"
				>
					CANCEL
				</t-button>
			</div>
		</form>
	</div>
</template>

<script>
import { cloneObject } from '@/tools/helpers.js';
import { ApiUpdateUser } from '@/tools/apiService';
import { mapActions, mapGetters } from 'vuex';
import FileUpload from '@/components/FileUpload';
export default {
	props: ['submit', 'withCancel'],
	data: () => ({
		form: {}
	}),
	mounted() {
		this.initUser();
	},
	watch: {
		user: function() {
			this.initUser();
		}
	},
	components: {
		FileUpload
	},
	computed: mapGetters({
		user: 'auth/user'
	}),
	methods: {
		...mapActions({
			fetchUser: 'auth/fetchUser'
		}),
		initUser: function() {
			if (this.user.client && this.user.client.phone) {
				let phone = this.user.client.phone;

				let phoneComponents = phone.split(' ');
				let dialCode = phoneComponents[0];

				let countryCode = this.$refs.vcc.filteredCountries.find(
					el => el.dialCode == dialCode
				);

				setTimeout(() => {
					this.$refs.vcc.activeCountry = countryCode
						? countryCode
						: this.$refs.vcc.activeCountry;
					this.form.dialCode = countryCode.dialCode;
				}, 500);

				if (countryCode) {
					phone = phoneComponents
						.filter((_, index) => (index == 0 ? false : true))
						.join(' ');
				}

				this.$set(this.form, 'phone', phone);
			}

			this.$set(this.form, 'name', this.user.name);
			this.$set(this.form, 'email', this.user.email);

			this.$set(
				this.form,
				'avatar',
				`${this.$constants.STORAGE_PATH}${this.user.avatar}`
			);
		},
		cancel: function() {
			this.$emit('cancel');
		},
		handleInput: function() {
			this.$emit('input', this.form);
		},
		updateUser: function() {
			let form = cloneObject(this.form);
			form.phone = `${form.dialCode} ${form.phone}`;
			ApiUpdateUser(form).then(() => {
				this.fetchUser();
				this.$emit('cancel');
			});
		}
	}
};
</script>
